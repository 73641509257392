import { SERVER_URL } from "../constants";
import { securedFetchRequest } from "./fetchRequest";

export const getCurrentUserToken = () => {
  return sessionStorage.getItem("t");
};

export const getUserID = () => {
  return sessionStorage.getItem("cid");
};

export const getUserType = () => {
  const userType = sessionStorage.getItem("user_type");
  return userType?.toUpperCase();
};

export const signOutUser = async () => {
  // await cognitoUser?.signOut();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/login";
};

export const fetchCurrentUser = () => {
  const userType = getUserType();
  const uid = getUserID();

  return securedFetchRequest(
    `${SERVER_URL}/${
      userType === "CANDIDATE" ? "candidates" : "companies"
    }/${uid}`
  ).catch((e) => {
    console.error("Error fetching user data :>>", e);
    signOutUser();
  });
};
