import React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Logo } from "../images/mavric_logo.svg";

export default function MavricLogoLoader() {
  return (
    <Grid
      container
      height="100vh"
      justifyContent="center"
      alignItems="center"
      bgcolor={`secondary.dark`}
    >
      <Logo width={120} height={120} />
      <Logo width={120} height={120} className="mavricLogoLoader" />
    </Grid>
  );
}
