import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { isMobile } from "../../utils/isMobile";

export default function TertiaryButton(props: ButtonProps) {
  const getSizeStyles = (size: string | undefined) => {
    if (isMobile) {
      if (size === "small") {
        return { fontSize: 13 };
      } else {
        return { fontSize: 14 };
      }
    } else {
      if (size === "small") {
        return { fontSize: 14 };
      } else {
        return { fontSize: 16 };
      }
    }
  };

  const sizeStyles = getSizeStyles(props.size);

  return (
    <Button
      {...props}
      sx={{
        ...props.sx,
        ...sizeStyles,
        color: props.color || `text.primary`,
        textTransform: "none",
        textAlign: "left",

        "&:hover": {
          backgroundColor: "transparent",
          "& .tertiary-button-text": {
            textDecoration: "underline",
          },
        },

        "& .MuiSvgIcon-root": {
          color: `text.primary`,
        },
      }}
    >
      <span className="tertiary-button-text">{props.children}</span>
    </Button>
  );
}
