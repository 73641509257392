import { atom } from "recoil";

interface ISnackbar {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

export const snackbarState = atom({
  key: "snackbarState",
  default: {
    open: false,
    message: "",
    severity: "success",
  } as ISnackbar,
});
