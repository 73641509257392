import { ReactNode } from "react";
import {
  AppBar,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { userState, userTypeState } from "../../../recoil/userAtom";
import { creditsState } from "../../../recoil/billingInfoAtom";
import { useRecoilValue } from "recoil";
import CommonMenuItems from "./CommonMenuItems";
import { AddCircleOutline } from "@mui/icons-material";
import theme from "../../../Theme";

import { ReactComponent as Logo } from "../../../images/mavric_logo.svg";
import { ReactComponent as DiscoverIcon } from "../../../images/icons/icon_discover_fill.svg";
import { ReactComponent as MatchesIcon } from "../../../images/icons/icon_matches_fill.svg";
import { ReactComponent as PostIcon } from "../../../images/icons/icon_post_fill.svg";
import { ReactComponent as InfoIcon } from "../../../images/icons/icon_info_fill.svg";
import { ReactComponent as SettingsIcon } from "../../../images/icons/icon_settings_fill.svg";
import { ReactComponent as ProfileIcon } from "../../../images/icons/icon_profile_fill.svg";
import { discoverJobsCounterState } from "../../../recoil/discoverJobsCounterAtom";

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    "& .MuiListItemIcon-root svg": {
      fill: theme.palette.text.secondary,
    },

    "&.Mui-selected": {
      backgroundColor: "transparent",
      "& .MuiListItemIcon-root svg": {
        fill: theme.palette.gold.dark,
      },
      "& .MuiListItemText-root span": {
        color: theme.palette.gold.dark,
      },
    },
  })
);

const DesktopMenuItem = (props: {
  icon: ReactNode;
  text: string;
  url?: string;
  selected: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <ListItem disablePadding onClick={() => props.url && navigate(props.url)}>
      <StyledListItemButton selected={props.selected}>
        <ListItemIcon sx={{ minWidth: 40 }}>{props.icon}</ListItemIcon>
        <ListItemText primary={props.text} />
      </StyledListItemButton>
    </ListItem>
  );
};

function HeaderDesktop() {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useRecoilValue(userState);
  const userType = useRecoilValue(userTypeState);
  const jobsCounter = useRecoilValue(discoverJobsCounterState);
  const credits = useRecoilValue(creditsState);

  return (
    <>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{ borderBottom: "1px solid", borderColor: `greyscale.light` }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              <Logo width={32} height={32} />
              <Typography
                variant="subtitle1"
                fontSize={20}
                fontWeight={500}
                ml={1}
              >
                Mavric
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Stack mr={4} direction="row" alignItems="center">
                <Typography variant="subtitle1" fontWeight={600}>
                  {location.pathname.includes("jobs") &&
                    `${jobsCounter} Jobs\u00A0\u00A0|\u00A0\u00A0`}
                  <span
                    style={{
                      color: location.pathname.includes("jobs")
                        ? theme.palette.gold.dark
                        : theme.palette.text.primary,
                    }}
                  >
                    {credits} Credits
                  </span>
                </Typography>
                <Tooltip arrow title="Add more credits">
                  <IconButton onClick={() => navigate("/billing/plans")}>
                    <AddCircleOutline sx={{ fill: theme.palette.gold.main }} />
                  </IconButton>
                </Tooltip>
              </Stack>

              <IconButton
                aria-label="go to profile view"
                onClick={() =>
                  navigate(
                    `/profile/view/${
                      userType === "CANDIDATE" ? "candidate" : "rep"
                    }`
                  )
                }
              >
                <ProfileIcon width="28" height="28" />
              </IconButton>
              <Typography variant="subtitle1" component="span">
                {user?.first_name} {user?.last_name}
              </Typography>
            </Stack>
          </Grid>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <Drawer
        sx={{
          width: 275,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            zIndex: 1000,
            width: 275,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />

        <List>
          <DesktopMenuItem
            icon={<DiscoverIcon />}
            text={`Discover ${
              userType === "CANDIDATE" ? "Jobs" : "Candidates"
            }`}
            url={`/discover/${userType === "CANDIDATE" ? "jobs" : "talent"}`}
            selected={location.pathname.includes("discover")}
          />
          <DesktopMenuItem
            icon={<MatchesIcon />}
            text="Matches"
            url="/matches"
            selected={location.pathname === "/matches"}
          />
          {userType === "COMPANY" && (
            <>
              <DesktopMenuItem
                icon={<PostIcon />}
                text="Job Posts"
                url="/job/posts"
                selected={location.pathname === "/job/posts"}
              />
            </>
          )}

          <ListItem>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
          <Collapse in>
            <CommonMenuItems
              section="about"
              handleClickListItem={(url) => url && navigate(url)}
            />
          </Collapse>

          <ListItem>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <Collapse in>
            <CommonMenuItems
              section="settings"
              handleClickListItem={(url) => url && navigate(url)}
            />
          </Collapse>
        </List>
      </Drawer>
    </>
  );
}

export default HeaderDesktop;
