import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useRecoilState } from "recoil";
import { snackbarState } from "../recoil/snackbarAtom";
import {
  ErrorOutline,
  InfoOutlined,
  TaskAlt,
  WarningAmber,
} from "@mui/icons-material";
import { isMobile } from "../utils/isMobile";

const icons = {
  success: <TaskAlt />,
  error: <ErrorOutline />,
  warning: <WarningAmber />,
  info: <InfoOutlined />,
};

export default function useSnackbar() {
  const [snackbarOptions, setSnackbarOptions] = useRecoilState(snackbarState);

  const openSnackbar = (
    message: string,
    severity: "success" | "error" | "warning" | "info" = "success"
  ) => {
    setSnackbarOptions({ open: true, message, severity });

    setTimeout(() => {
      setSnackbarOptions({ open: false, message: "", severity: "success" });
    }, 3300);
  };

  const MavricSnackbar = () => (
    <Snackbar
      open={snackbarOptions.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      onClose={() =>
        setSnackbarOptions({ open: false, message: "", severity: "success" })
      }
      sx={{
        paddingLeft:
          !isMobile && window.location.pathname.includes("discover")
            ? "275px"
            : 0,
      }}
    >
      <Alert
        severity={snackbarOptions.severity}
        variant="filled"
        icon={icons[snackbarOptions.severity]}
        sx={{
          // width: "100%",
          "&.MuiAlert-colorSuccess": { bgcolor: `forest.main` },
          "&.MuiAlert-colorError": { bgcolor: `brick.main` },
          "&.MuiAlert-colorWarning": { bgcolor: `gold.main` },
          "&.MuiAlert-colorInfo": { bgcolor: `eggplant.main` },
        }}
      >
        {snackbarOptions.message}
      </Alert>
    </Snackbar>
  );

  return { openSnackbar, MavricSnackbar };
}
