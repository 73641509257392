import React from "react";
import { TextField, TextFieldProps, styled } from "@mui/material";

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .Mui-focused fieldset": {
    borderColor: `${theme.palette.gold.main} !important`,
  },
  "& label.Mui-focused": {
    color: theme.palette.gold.main,
  },
  " .MuiFormHelperText-root": {
    color: theme.palette.greyscale.dark,
    marginTop: 8,
  },
}));

export default function MavricTextField(props: TextFieldProps) {
  return (
    <StyledTextField
      {...props}
      fullWidth
      // Styles applied to Menu when prop select={true}
      SelectProps={{
        MenuProps: {
          sx: {
            "& .MuiMenuItem-root": {
              "&.Mui-selected": {
                backgroundColor: `gold.medium2`,
                "&:hover": {
                  backgroundColor: `gold.light`,
                },
              },
              "&:hover": {
                backgroundColor: `gold.light`,
              },
            },
          },
        },
      }}
    />
  );
}
