import { atom } from "recoil";
import { IJobPosting } from "../utils/serverInterfaces";

export const companyJobPostsState = atom({
  key: "companyJobPostsState",
  default: [] as IJobPosting[],
});

export const jobPostsLoadingState = atom({
  key: "jobPostsLoadingState",
  default: false,
});
