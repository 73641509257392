import React from "react";
import { isMobile } from "../../utils/isMobile";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Save } from "@mui/icons-material";

export default function PrimaryButton(props: LoadingButtonProps) {
  const getSizeStyles = (size: string | undefined) => {
    if (isMobile) {
      if (size === "small") {
        return { height: 32, fontSize: 13 };
      } else {
        return { height: 44, fontSize: 14 };
      }
    } else {
      if (size === "small") {
        return { height: 40, fontSize: 14 };
      } else {
        return { height: 48, fontSize: 16 };
      }
    }
  };

  const getColorStyles = (color: string | undefined) => {
    if (color === "error") {
      return {
        backgroundColor: `brick.main`,
        "&:hover": { backgroundColor: `brick.dark` },
      };
    } else if (color === "success") {
      return {
        backgroundImage:
          "repeating-linear-gradient(45deg, #DBBC81, #BE903C 30%, #DBBC81 60% )",
        "&:hover": {
          backgroundImage:
            "repeating-linear-gradient(45deg, #BE903C, #AB8744 30%, #BE903C 60% )",
        },
      };
    } else {
      return {
        backgroundColor: `text.primary`,
        "&:hover": { backgroundColor: `text.secondary` },
      };
    }
  };

  const sizeStyles = getSizeStyles(props.size);
  const colorStyles = getColorStyles(props.color);

  return (
    <LoadingButton
      {...props}
      variant="contained"
      loadingPosition="start"
      startIcon={props.startIcon || <Save sx={{ display: "none" }} />}
      sx={{
        ...sizeStyles,
        ...colorStyles,
        color: "white",
        textTransform: "none",
        borderRadius: 50,
        paddingLeft:
          (isMobile && props.size === "small") || props.startIcon ? 2 : 3,
        paddingRight: isMobile && props.size === "small" ? 2 : 3,

        "& .MuiButton-startIcon": {
          fill: "white",
        },
      }}
    >
      {props.children}
    </LoadingButton>
  );
}
