import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import TertiaryButton from "../../common/buttons/TertiaryButton";
// import SecondaryButton from "../../common/buttons/SecondaryButton";
import { METHODS, fetchRequest } from "../../utils/fetchRequest";
import { SERVER_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";
import VerificationInput from "react-verification-input";

export default function VerificationFlow({
  setRenderVerification,
}: {
  setRenderVerification: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleClickVerify = () => {
    const username = sessionStorage.getItem("username");

    setLoading(true);
    fetchRequest(`${SERVER_URL}/users/verify`, METHODS.POST, {
      username,
      verification_code: verificationCode,
    }).then((res) => {
      setLoading(false);

      if (res.error) {
        openSnackbar(res.error, "error");
      } else {
        openSnackbar(res.message);
        setRenderVerification(false);
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    if (verificationCode.length === 6) {
      handleClickVerify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode.length]);

  // const handleClickResendCode = () => {
  //   resendVerificationCode(email);
  // };

  return (
    <Grid container direction="column">
      <Stack gap={2}>
        <VerificationInput
          value={verificationCode}
          length={6}
          validChars="0-9"
          placeholder=" "
          autoFocus
          onChange={(code) => setVerificationCode(code)}
          containerProps={{ style: { width: "100%" } }}
        />
        <PrimaryButton loading={loading} fullWidth onClick={handleClickVerify}>
          Verify Email
        </PrimaryButton>

        {/* <SecondaryButton onClick={handleClickResendCode}>
          Resend Code
        </SecondaryButton> */}

        <TertiaryButton
          onClick={() => setRenderVerification(false)}
          style={{ marginTop: 8 }}
        >
          Back to {window.location.pathname.replace("/", "")}
        </TertiaryButton>
      </Stack>
    </Grid>
  );
}
