import React, { Suspense, lazy, useEffect } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { userState, userTypeState } from "./recoil/userAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchCurrentUser,
  getCurrentUserToken,
  getUserID,
} from "./utils/getCurrentUser";
import useSnackbar from "./hooks/useSnackbar";
import { securedFetchRequest } from "./utils/fetchRequest";
import { SERVER_URL } from "./constants";
import {
  companyJobPostsState,
  jobPostsLoadingState,
} from "./recoil/companyJobPostsAtom";
import MavricLogoLoader from "./common/MavricLogoLoader";
import LoginScreen from "./modules/AuthScreen/LoginScreen";
import SignUpScreen from "./modules/AuthScreen/SignUpScreen";
import Navigation from "./modules/Navigation/Navigation";
import { billingInfoState } from "./recoil/billingInfoAtom";

const OnboardingFlowRouter = lazy(
  () => import("./modules/Onboarding/OnboardingFlowRouter")
);
const JobPostsList = lazy(
  () => import("./modules/JobPosts/JobPostsList/JobPostsList")
);
const MatchesList = lazy(() => import("./modules/Matches/MatchesList"));
const ProfileRouter = lazy(() => import("./modules/Profile/ProfileRouter"));
const Notifications = lazy(
  () => import("./modules/Notifications/Notifications")
);
const TeamManagement = lazy(() => import("./modules/Team/TeamManagement"));
const BillingPlans = lazy(
  () => import("./modules/Payments/BillingPlans/BillingPlans")
);
const MainSwipingList = lazy(
  () => import("./modules/SwipingScreen/MainSwipingList")
);
const ReverseRecruiting = lazy(
  () => import("./modules/StaticPages/ReverseRecruiting")
);
const StrategicHiring = lazy(
  () => import("./modules/StaticPages/StrategicHiring")
);
const MavricCommunity = lazy(
  () => import("./modules/StaticPages/MavricCommunity")
);
const PaymentSuccess = lazy(
  () => import("./modules/Payments/PaymentResultsHandling/PaymentSuccess")
);

function App() {
  const path = window.location.pathname;

  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const userType = useRecoilValue(userTypeState);
  const [companyJobPosts, setCompanyJobPosts] =
    useRecoilState(companyJobPostsState);
  const setJobPostsLoading = useSetRecoilState(jobPostsLoadingState);
  const billingInfo = useSetRecoilState(billingInfoState);

  const { MavricSnackbar } = useSnackbar();

  useEffect(() => {
    const token = getCurrentUserToken();
    const uid = getUserID();

    if (token) {
      if (!userType) {
        navigate("/account/new");
        return;
      }

      if (!user && uid && !["/login", "/signup"].includes(path)) {
        fetchCurrentUser().then((userData) => {
          console.log("User :>> ", userData);
          setUser({ ...userData });
        });
      }
    } else if (path !== "/signup") {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      user?.cognito_id &&
      userType === "COMPANY" &&
      !path.includes("/account/new")
    ) {
      setJobPostsLoading(true);
      securedFetchRequest(
        `${SERVER_URL}/companies/${user?.cognito_id}/jobs`
      ).then((res) => {
        setCompanyJobPosts(res);
        setJobPostsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCompanyJobPosts, setJobPostsLoading, user?.cognito_id, userType]);

  useEffect(() => {
    if (user) {
      if (userType) {
        securedFetchRequest(`${SERVER_URL}/get-billing-info`).then(
          ({ billing_info }) => {
            billingInfo((defaultBillingInfo) => ({
              ...(billing_info ?? defaultBillingInfo),
            }));
          }
        );
      }
    }
    //eslint-disable-next-line
  }, [user, userType, companyJobPosts.length]);

  useEffect(() => {
    if (user) {
      if (userType === "CANDIDATE") {
        if (
          user?.candidate_profile?.travel_preference?.length === 0 &&
          path !== "/account/new/candidate"
        ) {
          navigate("/account/new/candidate");
          return;
        }
        if (path === "/" || path === "/discover/talent") {
          navigate("/discover/jobs");
        }
      } else if (userType === "COMPANY") {
        if (
          user?.company_profile?.values?.length === 0 &&
          path !== "/account/new/company"
        ) {
          navigate("/account/new/company");
          return;
        }
        if (path === "/" || path === "/discover/jobs") {
          navigate("/discover/talent");
        }
      }
    }
    // eslint-disable-next-line
  }, [navigate, path, user, userType]);

  return (
    <div className="app">
      <Suspense fallback={<MavricLogoLoader />}>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/account/new/*" element={<OnboardingFlowRouter />} />

          <Route path="/" element={<Navigation />}>
            <Route
              path="/discover/jobs"
              element={<MainSwipingList type="job" />}
            />
            <Route
              path="/discover/talent"
              element={<MainSwipingList type="talent" />}
            />

            <Route path="/matches" element={<MatchesList />} />
            <Route path="/job/posts/*" element={<JobPostsList />} />

            <Route path="/profile/*" element={<ProfileRouter />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/billing/plans" element={<BillingPlans />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />

            <Route path="/team" element={<TeamManagement />} />

            <Route path="/reverse-recruiting" element={<ReverseRecruiting />} />
            <Route path="/strategic-hiring" element={<StrategicHiring />} />
            <Route path="/mavric-community" element={<MavricCommunity />} />

            <Route
              path="*"
              element={
                <div style={{ padding: 24, textAlign: "center" }}>
                  404: PAGE NOT FOUND
                </div>
              }
            />
          </Route>
        </Routes>
      </Suspense>

      <MavricSnackbar />
    </div>
  );
}

export default App;
