import { atom, selector } from "recoil";
import { IUser } from "../utils/serverInterfaces";
import { getUserType } from "../utils/getCurrentUser";

export const userTypeState = selector({
  key: "userTypeState",
  get: ({ get }) => {
    const user = get(userState);
    const userType = user?.user_type
      ? user?.user_type?.toUpperCase()
      : getUserType();
    return userType as "CANDIDATE" | "COMPANY";
  },
});

export const userState = atom({
  key: "userState", // unique ID (with respect to other atoms/selectors)
  default: null as IUser | null,
});
