import React from "react";
import { Outlet } from "react-router-dom";
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import Footer from "./components/Footer";
import { isMobile } from "../../utils/isMobile";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/userAtom";
import MavricLogoLoader from "../../common/MavricLogoLoader";

export default function Navigation() {
  const user = useRecoilValue(userState);

  if (!user) {
    return <MavricLogoLoader />;
  }

  return (
    <>
      {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
      <div style={{ marginLeft: isMobile ? 0 : 275 }}>
        <Outlet />
      </div>
      {isMobile && <Footer />}
    </>
  );
}
