import React, { useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TertiaryButton from "../../common/buttons/TertiaryButton";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import MavricTextField from "../../common/inputs/MavricTextField";
import { ReactComponent as Logo } from "../../images/mavric_logo.svg";
// import { ReactComponent as Google } from "../../images/google_logo.svg";
// import SecondaryButton from "../../common/buttons/SecondaryButton";
import AnonymousContainer from "../../common/containers/AnonymousContainer";
import SimpleReactValidator from "simple-react-validator";
import { METHODS, fetchRequest } from "../../utils/fetchRequest";
import { SERVER_URL } from "../../constants";
import VerificationInput from "./VerificationFlow";

export default function SignUpScreen() {
  const navigate = useNavigate();
  const validator = useRef(
    new SimpleReactValidator({ messages: { in: "The passwords must match" } })
  );
  const [errors, setErrors] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [signupErr, setSignupErr] = useState("");
  const [renderVerification, setRenderVerification] = useState(false);

  const handleClickSignUp = () => {
    if (!validator.current.allValid()) {
      ["firstName", "lastName", "email", "password", "confirmPassword"].forEach(
        (field) => {
          if (!validator.current.fieldValid(field)) {
            validator.current.showMessageFor(field);
            setErrors({ ...errors, field: true });
            return;
          }
          validator.current.hideMessageFor(field);
          setErrors({ ...errors, field: false });
        }
      );
      return;
    }

    setLoading(true);
    fetchRequest(`${SERVER_URL}/users`, METHODS.POST, {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    })
      .then((res) => {
        setLoading(false);

        if (res.error) {
          if (res.error.includes("Password did not conform with policy")) {
            setSignupErr(
              "Password must be at least 8 characters and have a lowercase character, a number, and a symbol."
            );
          } else {
            setSignupErr(res.error);
          }
        } else if (res.success) {
          localStorage.setItem(
            "new_user",
            JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email: email,
              cognito_id: res.cognito_id,
            })
          );
          sessionStorage.setItem("username", res.username);
          sessionStorage.setItem("t", res.id_token);
          sessionStorage.setItem("cid", res.cognito_id);

          setRenderVerification(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSignupErr(err.message);
      });
  };

  return (
    <AnonymousContainer>
      <Grid p={2} container direction="column" alignItems="center">
        <Box pt={2}>
          <Logo width={72} height={72} />
        </Box>
        <Typography my={2} variant="h5">
          Sign up
        </Typography>
        <Typography mb={4} variant="body2" color="greyscale.dark">
          Welcome to Mavric!
        </Typography>

        {!renderVerification ? (
          <>
            <Grid container mb={1} spacing={1}>
              <Grid item xs={6}>
                <MavricTextField
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                  helperText={validator.current.message(
                    "firstName",
                    firstName,
                    "required"
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <MavricTextField
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="family-name"
                  helperText={validator.current.message(
                    "lastName",
                    lastName,
                    "required"
                  )}
                />
              </Grid>
            </Grid>
            <MavricTextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              helperText={validator.current.message(
                "email",
                email,
                "required|email"
              )}
              sx={{ mb: 1 }}
            />
            <MavricTextField
              fullWidth
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              helperText={validator.current.message(
                "password",
                password,
                "required|min:8"
              )}
              sx={{ mb: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MavricTextField
              fullWidth
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleClickSignUp()}
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              helperText={validator.current.message(
                "confirmPassword",
                confirmPassword,
                `required|min:8|in:${password}`
              )}
              sx={{ mb: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <PrimaryButton
              loading={loading}
              fullWidth
              type="submit"
              onClick={handleClickSignUp}
            >
              Continue with email
            </PrimaryButton>
            <Typography my={1} variant="body2" color="error">
              {signupErr}
            </Typography>

            {/* <SecondaryButton fullWidth startIcon={<Google />}>
              Continue with Google
            </SecondaryButton> */}

            <Typography my={2} variant="body2" color="greyscale.dark">
              Already have an account?
            </Typography>
            <TertiaryButton onClick={() => navigate("/login")}>
              Log In
            </TertiaryButton>
          </>
        ) : (
          <VerificationInput setRenderVerification={setRenderVerification} />
        )}
      </Grid>
    </AnonymousContainer>
  );
}
