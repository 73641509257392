import { getCurrentUserToken, signOutUser } from "./getCurrentUser";

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  OPTIONS: "OPTIONS",
};

export const fetchRequest = (
  url: string,
  method: string = "GET",
  body?: any
) => {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    method,
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

export const securedFetchRequest = (
  url: string,
  method: string = "GET",
  body?: any
) => {
  const token = getCurrentUserToken();

  if (!token) {
    signOutUser();
  }

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (
        res.message === "The incoming token has expired" ||
        res.error?.includes("Token is expired")
      ) {
        signOutUser();
      }
      if (res.error) {
        throw new Error(res.error);
      }
      return res;
    });
};
