import React, { useEffect, useState } from "react";
import {
  AppBar,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { headingTextState } from "../../../recoil/headingTextAtom";
import { AddCircleOutline, Close, Menu } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CommonMenuItems from "./CommonMenuItems";
import theme from "../../../Theme";
import { ReactComponent as Logo } from "../../../images/mavric_logo.svg";
import { discoverJobsCounterState } from "../../../recoil/discoverJobsCounterAtom";
import { creditsState } from "../../../recoil/billingInfoAtom";

function HeaderMobile() {
  const navigate = useNavigate();
  const location = useLocation();

  const headingText = useRecoilValue(headingTextState);
  const jobsCounter = useRecoilValue(discoverJobsCounterState);
  const credits = useRecoilValue(creditsState);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickListItem = (url: string) => {
    if (url === location.pathname) {
      setDrawerOpen(false);
      return;
    }
    navigate(url);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          bgcolor:
            window.location.pathname.includes("discover") ||
            window.location.pathname.includes("profile")
              ? `secondary.dark`
              : `secondary.main`,
          borderBottom:
            window.location.pathname.includes("discover") ||
            window.location.pathname.includes("profile")
              ? "none"
              : "1px solid",
          borderColor: `greyscale.light`,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <IconButton
              aria-label="open settings drawer"
              onClick={() => setDrawerOpen(true)}
              sx={{ padding: 0 }}
            >
              <Menu color="primary" fontSize="large" />
            </IconButton>

            {location.pathname.includes("discover") ? (
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="subtitle1"
                  component="span"
                  fontWeight={600}
                  fontSize={17}
                >
                  {location.pathname.includes("jobs") &&
                    `${jobsCounter} Jobs\u00A0\u00A0|\u00A0\u00A0`}
                  <span
                    style={{
                      color: location.pathname.includes("jobs")
                        ? theme.palette.gold.dark
                        : theme.palette.text.primary,
                    }}
                  >
                    {credits} Credits
                  </span>
                </Typography>
                <Tooltip arrow title="Add more credits">
                  <IconButton
                    onClick={() => navigate("/billing/plans")}
                    sx={{ ml: -0.5 }}
                  >
                    <AddCircleOutline sx={{ fill: theme.palette.gold.main }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  component="span"
                  fontWeight={600}
                  fontSize={17}
                >
                  {headingText}
                </Typography>
                <div style={{ width: 24 }} />
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        PaperProps={{
          sx: {
            width: "90vw",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          },
        }}
      >
        <Stack p={3} gap={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              <Logo width={32} height={32} />
              <Typography
                variant="subtitle1"
                fontSize={20}
                fontWeight={500}
                ml={1}
              >
                Mavric
              </Typography>
            </Stack>

            <IconButton
              aria-label="close settings drawer"
              onClick={() => setDrawerOpen(false)}
            >
              <Close color="primary" />
            </IconButton>
          </Grid>

          <Stack gap={1}>
            <Typography variant="h6">About</Typography>
            <CommonMenuItems
              section="about"
              handleClickListItem={handleClickListItem}
            />
          </Stack>

          <Stack gap={1}>
            <Typography variant="h6">Settings</Typography>
            <CommonMenuItems
              section="settings"
              handleClickListItem={handleClickListItem}
            />
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </>
  );
}

export default HeaderMobile;
