import React from "react";
import { AppBar, Box, Card, Grid, Toolbar, Typography } from "@mui/material";
import { isMobile } from "../../utils/isMobile";
import { ReactComponent as Logo } from "../../images/mavric_logo.svg";

export default function AnonymousContainer(props: { children: any }) {
  return isMobile ? (
    <Box height="auto">{props.children}</Box>
  ) : (
    <Box>
      <AppBar
        color="secondary"
        elevation={0}
        sx={{ borderBottom: "1px solid", borderColor: `greyscale.light` }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Logo width={32} height={32} />
            <Typography variant="subtitle1" fontSize={20} ml={1}>
              Mavric
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Grid
        container
        height="100vh"
        justifyContent="center"
        alignItems="center"
        sx={{ maxHeight: "calc(100vh - 64px)", overflowY: "auto" }}
      >
        <Card
          variant="outlined"
          sx={{ m: 2, p: 1, width: 440, borderRadius: "10px" }}
        >
          {props.children}
        </Card>
      </Grid>
    </Box>
  );
}
