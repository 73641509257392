import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    greyscale: Palette["primary"];
    gold: Palette["primary"];
    forest: Palette["primary"];
    eggplant: Palette["primary"];
    brick: Palette["primary"];
  }

  interface PaletteOptions {
    greyscale?: PaletteOptions["primary"];
    gold?: PaletteOptions["primary"];
    forest?: PaletteOptions["primary"];
    eggplant?: PaletteOptions["primary"];
    brick?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
    medium?: string;
    medium2?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
    medium?: string;
    medium2?: string;
  }
}

// declare module "@mui/material/Button" {
//   interface ButtonPropsColorOverrides {
//     brick: true;
//     gold: true;
//   }
// }

const theme = createTheme({
  palette: {
    primary: {
      main: "#000", // Black
    },
    secondary: {
      main: "#FFF", // White
      dark: "#FAFAFA", // Backgrounds
      darker: "#F0F0EF",
    },
    text: {
      primary: "#121212", // Headings, Labels
      secondary: "#525251",
    },
    greyscale: {
      main: "#B1B1AF", // 69%
      dark: "#787774", // 46%
      darker: "#2B2B2B", // 17%
      light: "#D9D9D8", // 85%
    },
    // Brand Gold
    gold: {
      main: "#C09E5F",
      dark: "#AB8744",
      darker: "#88692D", // Used for text when overlayed on "light" option
      light: "#FBF8F3",
      medium: "#DBBC81",
      medium2: "#ECD6AC",
    },
    // Green
    forest: {
      main: "#37924B",
      dark: "#377F47",
      darker: "#33603D", // Used for text when overlayed on "light" option
      light: "#F5FAF6",
    },
    // Purple
    eggplant: {
      main: "#7F3DA8",
      dark: "#773C9B",
      darker: "#5D3B72", // Used for text when overlayed on "light" option
      light: "#F8F4FB",
    },
    // Red
    brick: {
      main: "#C6492D",
      dark: "#BC482E",
      darker: "#AD4630", // Used for text when overlayed on "light" option
      light: "#FCF4F3",
    },
  },

  typography: {
    fontFamily: "Poppins",
    fontWeightRegular: 400,
    fontWeightBold: 700,

    // Headings
    h1: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },
    h2: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },
    h3: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },
    h4: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },
    h5: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },
    h6: {
      lineHeight: "135%",
      fontWeight: 700,
      color: "#121212",
    },

    // Subheadings (h6 element)
    subtitle1: {
      lineHeight: "100%",
      color: "#121212",
    },
    subtitle2: {
      lineHeight: "100%",
      color: "#121212",
    },

    // Paragraphs
    body1: {
      lineHeight: "150%",
      color: "#525251",
    },
    body2: {
      lineHeight: "150%",
      color: "#525251",
    },

    // Other
    button: {
      lineHeight: "100%",
    },
    caption: {
      lineHeight: "100%",
    },
    overline: {
      lineHeight: "100%",
    },
  },
});

export default theme;
