import { atom, selector } from "recoil";
import { IBillingInfo } from "../utils/serverInterfaces";

export const billingInfoState = atom({
  key: "billingInfoState",
  default: {
    lookupKey: null,
    credits: 0,
    job_ads: null,
  } as IBillingInfo | null,
});

export const lookupKeyState = selector({
  key: "lookupKey",
  get: ({ get }) => {
    const billingInfo = get(billingInfoState);
    return billingInfo?.lookupKey;
  },
});

export const creditsState = selector({
  key: "creditsState",
  get: ({ get }) => {
    const billingInfo = get(billingInfoState);
    return billingInfo?.credits;
  },
});

export const jobAdsLeftState = selector({
  key: "jobAdsLeftState",
  get: ({ get }) => {
    const billingInfo = get(billingInfoState);
    return billingInfo?.job_ads;
  },
});

export const userSubscriptionState = selector({
  key: "userSubscriptionState",
  get: ({ get }) => {
    const billingInfo = get(billingInfoState);
    const plan = billingInfo?.lookupKey;

    if (plan) {
      return plan.split("_")[1];
    } else {
      return null;
    }
  },
});
