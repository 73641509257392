import React from "react";
import {
  AppBar,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../../Theme";

import { ReactComponent as DiscoverIcon } from "../../../images/icons/icon_discover_fill.svg";
import { ReactComponent as MatchesIcon } from "../../../images/icons/icon_matches_fill.svg";
import { ReactComponent as PostIcon } from "../../../images/icons/icon_post_fill.svg";
import { useRecoilValue } from "recoil";
import { userTypeState } from "../../../recoil/userAtom";

function Footer() {
  const userType = useRecoilValue(userTypeState);
  const navigate = useNavigate();

  const getIconColor = (pathname: string) => {
    if (window.location.pathname.includes(pathname)) {
      return theme.palette.gold.darker;
    } else {
      return theme.palette.greyscale.darker;
    }
  };

  return (
    <AppBar
      position="fixed"
      color="secondary"
      elevation={0}
      sx={{
        top: "auto",
        bottom: 0,
        borderTop: "1px solid",
        borderColor: `greyscale.light`,
      }}
    >
      <Toolbar>
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Stack alignItems="center">
            <IconButton
              aria-label="go to discover page"
              onClick={() =>
                navigate(
                  `/discover/${userType === "CANDIDATE" ? "jobs" : "talent"}`
                )
              }
            >
              <DiscoverIcon fill={getIconColor("discover")} />
            </IconButton>
            <Typography
              mt={-0.5}
              mb={0.5}
              fontSize={11}
              variant="caption"
              color={getIconColor("discover")}
            >
              Discover
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="go to matches page"
              onClick={() => navigate("/matches")}
            >
              <MatchesIcon fill={getIconColor("matches")} />
            </IconButton>
            <Typography
              mt={-0.5}
              mb={0.5}
              fontSize={11}
              variant="caption"
              color={getIconColor("matches")}
            >
              Matches
            </Typography>
          </Stack>

          {userType === "COMPANY" && (
            <Stack alignItems="center">
              <IconButton
                aria-label="go to jobs list"
                onClick={() => navigate("/job/posts")}
              >
                <PostIcon fill={getIconColor("job")} />
              </IconButton>
              <Typography
                mt={-0.5}
                mb={0.5}
                fontSize={11}
                variant="caption"
                color={getIconColor("job")}
              >
                Jobs
              </Typography>
            </Stack>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
