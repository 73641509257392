import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TertiaryButton from "../../common/buttons/TertiaryButton";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import MavricTextField from "../../common/inputs/MavricTextField";
import { ReactComponent as Logo } from "../../images/mavric_logo.svg";
// import { ReactComponent as Google } from "../../images/google_logo.svg";
// import SecondaryButton from "../../common/buttons/SecondaryButton";
import AnonymousContainer from "../../common/containers/AnonymousContainer";
import { METHODS, fetchRequest } from "../../utils/fetchRequest";
import { SERVER_URL } from "../../constants";
import { userState } from "../../recoil/userAtom";
import { useSetRecoilState } from "recoil";
import { fetchCurrentUser } from "../../utils/getCurrentUser";
import VerificationInput from "./VerificationFlow";

export default function LoginScreen() {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loginErr, setLoginErr] = useState("");
  const [renderVerification, setRenderVerification] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickLogIn = () => {
    setLoading(true);
    fetchRequest(`${SERVER_URL}/login`, METHODS.POST, { email, password })
      .then((res) => {
        setLoading(false);

        if (res.error) {
          setLoginErr(res.error);
          if (res.error.includes("User is not confirmed")) {
            setRenderVerification(true);
          }
        } else if (res.success) {
          setLoginErr("");

          sessionStorage.setItem("t", res.id_token);

          if (res.user_type) {
            sessionStorage.setItem("cid", res.cognito_id);
            sessionStorage.setItem("user_type", res.user_type);

            fetchCurrentUser().then((userData) => {
              setUser({ ...userData });
            });
            navigate("/");
          } else {
            navigate("/account/new");
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <AnonymousContainer>
      <Grid p={2} pb={6} container direction="column" alignItems="center">
        <Box pt={6}>
          <Logo width={72} height={72} />
        </Box>
        <Typography my={2} variant="h5">
          Log in
        </Typography>
        <Typography mb={4} variant="body2" color="greyscale.dark">
          Welcome back to Mavric!
        </Typography>

        {!renderVerification ? (
          <>
            <MavricTextField
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              label="Email"
              autoComplete="email"
              helperText=""
              sx={{ mb: 1 }}
            />
            <MavricTextField
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleClickLogIn()}
              type={showPassword ? "text" : "password"}
              label="Password"
              autoComplete="new-password"
              helperText=""
              sx={{ mb: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <PrimaryButton
              loading={loading}
              fullWidth
              type="submit"
              onClick={handleClickLogIn}
            >
              Continue with email
            </PrimaryButton>
            <Typography my={1} variant="body2" color="error">
              {loginErr}
            </Typography>

            {/* <SecondaryButton fullWidth startIcon={<Google />}>
              Continue with Google
            </SecondaryButton> */}

            <Typography my={2} variant="body2" color="greyscale.dark">
              Need an account?
            </Typography>
            <TertiaryButton onClick={() => navigate("/signup")}>
              Sign Up
            </TertiaryButton>
          </>
        ) : (
          <VerificationInput setRenderVerification={setRenderVerification} />
        )}
      </Grid>
    </AnonymousContainer>
  );
}
