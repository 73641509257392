import React from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { isMobile } from "../../../utils/isMobile";
import { useRecoilValue } from "recoil";
import { userTypeState } from "../../../recoil/userAtom";
import {
  getCurrentUserToken,
  signOutUser,
} from "../../../utils/getCurrentUser";

export default function CommonMenuItems({
  section,
  handleClickListItem,
}: {
  section: string;
  handleClickListItem: (url: string) => void;
}) {
  const userType = useRecoilValue(userTypeState);

  const CommonListItem = ({
    text,
    onClick,
    selected,
  }: {
    text: string;
    onClick: () => void;
    selected?: boolean;
  }) => {
    return (
      <ListItemButton
        sx={{
          pl: isMobile ? 0 : 8,
          py: 0.5,
        }}
        onClick={onClick}
      >
        <ListItemText
          primary={text}
          sx={{
            "&.MuiListItemText-root span": {
              color: selected ? `gold.dark` : `text.secondary`,
            },
          }}
        />
      </ListItemButton>
    );
  };

  const getMenuItems = () => {
    switch (section) {
      case "about": {
        return (
          <List component="div" disablePadding={!isMobile}>
            {userType === "CANDIDATE" && (
              <CommonListItem
                text="Reverse Recruiting"
                onClick={() => handleClickListItem("/reverse-recruiting")}
                selected={window.location.pathname === "/reverse-recruiting"}
              />
            )}
            {userType === "COMPANY" && (
              <>
                <CommonListItem
                  text="Strategic Hiring"
                  onClick={() => handleClickListItem("/strategic-hiring")}
                  selected={window.location.pathname === "/strategic-hiring"}
                />
                <CommonListItem
                  text="Mavric Community"
                  onClick={() => handleClickListItem("/mavric-community")}
                  selected={window.location.pathname === "/mavric-community"}
                />
              </>
            )}
          </List>
        );
      }

      case "settings": {
        return (
          <List component="div" disablePadding={!isMobile}>
            <CommonListItem
              text="My Profile"
              onClick={() =>
                handleClickListItem(
                  `/profile/view/${
                    userType === "CANDIDATE" ? "candidate" : "rep"
                  }`
                )
              }
              selected={[
                "/profile/view/rep",
                "/profile/view/candidate",
              ].includes(window.location.pathname)}
            />

            {userType === "COMPANY" && (
              <CommonListItem
                text="Company Profile"
                onClick={() => handleClickListItem("/profile/view/company")}
                selected={window.location.pathname === "/profile/view/company"}
              />
            )}
            <CommonListItem
              text="Plans & Billing"
              onClick={() => handleClickListItem("/billing/plans")}
              selected={window.location.pathname === "/billing/plans"}
            />
            <CommonListItem
              text="Notifications"
              onClick={() => handleClickListItem("/notifications")}
              selected={window.location.pathname === "/notifications"}
            />
            {/* <CommonListItem
                  text="Team"
                  onClick={() => handleClickListItem("/team")}
                  selected={window.location.pathname === "/team"}
                /> */}
            {getCurrentUserToken() && (
              <CommonListItem text="Sign Out" onClick={signOutUser} />
            )}
          </List>
        );
      }

      default:
        return null;
    }
  };

  return getMenuItems();
}
